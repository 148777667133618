






















import KWrapper from '@/@core/components/other/KWrapper.vue';
import KPage from '@/@core/components/page/KPage.vue';
import KPageBody from '@/@core/components/page/KPageBody.vue';
import KText from '@/@core/components/typography/KText.vue';
import { isMobile } from '@/@core/helpers/useBreakpoint';
import router from '@/router';
import { defineComponent, ref } from '@vue/composition-api';
import AuthLayout from '../../ui/AuthLayout.vue';
import RegisterForm from '../../ui/RegisterForm.vue';

export default defineComponent({
  components: {
    KPage,
    KPageBody,
    KWrapper,
    KText,
    AuthLayout,
    RegisterForm
  },
  name: 'AuthRegisterPage',
  setup() {
    const isLoading = ref(false);
    const onSubmit = (data: any) => {
      isLoading.value = true;

      setTimeout(() => {
        console.log(data);
        isLoading.value = false;
        router.push('/app');
      }, 1500);
    };

    return {
      isLoading,
      onSubmit,
      isMobile
    };
  }
});
